import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import "../styles/success.css" 

const Success = () => (
  <Layout>
    <Seo title="Thank You" />
    <div id="successContainer">
      <h3>Thanks a lot for contacting us!</h3>
      <p>Your message has been received successfully!</p>
      <p>We'll get back to you soon!</p>
    </div>
  </Layout>
)

export default Success

// Ignore following words from checking by Code Spell Checker
// cSpell:ignore akinul jony frontmatter